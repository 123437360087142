import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [items, setItems] = useState([]);
  const [newItemName, setNewItemName] = useState('');
  const [error, setError] = useState(null);

  const API_URL = 'https://cfapi.lixbins.workers.dev/api/items';

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) throw new Error('获取数据失败');
      const data = await response.json();
      setItems(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const addItem = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: newItemName }),
      });
      if (!response.ok) throw new Error('添加项目失败');
      await fetchItems();
      setNewItemName('');
    } catch (err) {
      setError(err.message);
    }
  };

  const updateItem = async (id, newName) => {
    try {
      const response = await fetch(`${API_URL}/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: newName }),
      });
      if (!response.ok) throw new Error('更新项目失败');
      await fetchItems();
    } catch (err) {
      setError(err.message);
    }
  };

  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${API_URL}/${id}`, { method: 'DELETE' });
      if (!response.ok) throw new Error('删除项目失败');
      await fetchItems();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="App">
      <h1>项目列表</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={addItem}>
        <input
          type="text"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          placeholder="新项目名称"
        />
        <button type="submit">添加项目</button>
      </form>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            {item.name}
            <button onClick={() => updateItem(item.id, prompt('新名称', item.name))}>
              更新
            </button>
            <button onClick={() => deleteItem(item.id)}>删除</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;